<template>
    <CModal
        v-if="dailyReport"
        title="日報削除"
        color="dark"
        size="sm"
        :show.sync="isOpened">
        <div class="py-1">日付：{{ this.$moment(dailyReport.attributes.register_date).format('YYYY年MM月DD日') }}</div>
        <div class="py-1">対象者名：{{ dailyReport.relationships.person.attributes.name }}</div>
        <template #footer>
            <button type="button" class="btn btn-danger" @click="deleteConfirm" :disabled="sending">削除</button>
        </template>
    </CModal>
</template>

<script>
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "DeleteDailyReportModal",
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            dailyReport: null
        }
    },
    methods: {
        openModal: function (dailyReport) {
            this.isOpened = true;
            this.dailyReport = dailyReport;
        },
        deleteDailyReport: function () {
            this.sending = true;
            this.showIndicator('削除中');

            axios.delete(this.routes.dailyReport(this.dailyReport.id))
                .then(() => {
                    this.$emit('dailyReport-has-deleted', this.dailyReport.id);
                    this.showSuccessPopup('削除しました');
                    this.clearErrors();
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        deleteConfirm: function () {
            this.showDeleteConfirmPopup(() => {
                this.deleteDailyReport();
            })
        }
    }
}
</script>

<style scoped>

</style>
