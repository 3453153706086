<template>
    <div v-if="dailyReports.length > 0">
        <h3 class="my-4">#主観的症状</h3>
        <table class="table table-bordered bg-white">
            <thead>
            <tr class="bg-dark">
                <th style="width: 24%"></th>
                <th style="width: 38%">平均</th>
                <th style="width: 38%">ばらつき</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row" class="bg-dark">今の気分</th>
                <td>{{ this.ave(0) }}</td>
                <td>{{ this.standardDeviation(0) }}</td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">症状①</th>
                <td>{{ this.ave(11) }}</td>
                <td>{{ this.standardDeviation(11) }}</td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">症状②</th>
                <td>{{ this.ave(12) }}</td>
                <td>{{ this.standardDeviation(12) }}</td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">症状③</th>
                <td>{{ this.ave(13) }}</td>
                <td>{{ this.standardDeviation(13) }}</td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">症状④</th>
                <td>{{ this.ave(14) }}</td>
                <td>{{ this.standardDeviation(14) }}</td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">症状⑤</th>
                <td>{{ this.ave(15) }}</td>
                <td>{{ this.standardDeviation(15) }}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div v-else>
        <h3 class="my-4">#主観的症状</h3>
        <table class="table table-bordered bg-white">
            <thead>
            <tr class="bg-dark">
                <th style="width: 24%"></th>
                <th style="width: 38%">平均</th>
                <th style="width: 38%">ばらつき</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row" class="bg-dark">今の気分</th>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">症状①</th>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">症状②</th>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">症状③</th>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">症状④</th>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">症状⑤</th>
                <td></td>
                <td></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "DailyReportCondition",
    props: {
        dailyReports: {
            type: Array,
            require: false,
            default: null
        },
    },
    methods: {
        isDailyReports: function () {
            return this.dailyReports.length > 0;
        },
        round: function (data, digit) {
            if (data === 0) return 0;
            return Math.round(data * digit) / digit;
        },
        ave: function (index) {
            if (this.isDailyReports()) {
                const sum = this.dailyReports.reduce((prev, current) =>
                    prev + parseInt(current['relationships']['dailyReportDetails'][index][0]['attributes']['point']), 0);

                const ave = sum / this.dailyReports.length;

                return this.round(ave,1);
            }
        },
        standardDeviation: function (index) {
            if (this.isDailyReports()) {
                const average =
                    this.dailyReports.reduce((prev, current) => prev + parseInt(current['relationships']['dailyReportDetails'][index][0]['attributes']['point']), 0)
                    / this.dailyReports.length;

                const sd = Math.sqrt(
                    this.dailyReports.map((current) => {
                        let difference = parseInt(current['relationships']['dailyReportDetails'][index][0]['attributes']['point']) - average;
                        return difference ** 2;
                    })
                        .reduce((prev, current) =>
                            prev + current
                        ) / this.dailyReports.length
                );

                return this.round(sd,1);
            }
        }
    }
}
</script>

<style scoped>

</style>
