<template>
    <CModal
        v-if="dailyReport"
        :title="'日報編集 <' + this.$moment(dailyReport.attributes.register_date).format('YYYY年MM月DD日') + '>'"
        color="dark"
        size="xl"
        :show.sync="isOpened">
        <div class="container-fluid p-3" v-if="dailyReport">
            <div class="row pb-5">
                <div class="col-xl-6 pb-3">
                    <form>
                        <h5 class="font-weight-bold pb-3">#バイタル</h5>
                        <div class="form">
                            <v-input label="最高血圧"
                                     id="display-max"
                                     type="text"
                                     :value="dailyReport.attributes.pressure_max"
                                     disabled>
                            </v-input>
                            <v-input label="最低血圧"
                                     id="display-min"
                                     type="text"
                                     :value="dailyReport.attributes.pressure_min"
                                     disabled>
                            </v-input>
                            <v-input label="平均血圧"
                                     id="display-ave"
                                     type="text"
                                     :value="dailyReport.attributes.pressure_ave"
                                     disabled>
                            </v-input>
                            <v-input label="脈拍"
                                     id="display-pulse"
                                     type="text"
                                     :value="dailyReport.attributes.pulse"
                                     disabled
                                     unit="回/分">
                            </v-input>
                            <v-input label="体温"
                                     id="display-body-temperature"
                                     type="text"
                                     :value="dailyReport.attributes.body_temperature"
                                     unit="度"
                                     disabled>
                            </v-input>
                        </div>
                    </form>
                </div>
                <div class="col-xl-6 pb-3">
                    <h5 class="font-weight-bold pb-3">#生活リズム</h5>
                    <form>
                        <div class="form">
                            <v-input label="就寝時間"
                                     id="display-bed-time"
                                     type="text"
                                     :value="this.$moment(dailyReport.attributes.bed_time).format('MM月DD日 HH時mm分')"
                                     disabled>
                            </v-input>
                            <v-input label="起床時間"
                                     id="display-wakeup-time"
                                     type="text"
                                     :value="this.$moment(dailyReport.attributes.wake_time).format('MM月DD日 HH時mm分')"
                                     disabled>
                            </v-input>
                            <v-input label="睡眠の質"
                                     id="display-sleep-quality"
                                     type="text"
                                     :unit="dailyReport.relationships.dailyReportDetails[2][0].attributes.point + '点'"
                                     :value="sleepQuality(dailyReport.relationships.dailyReportDetails[2][0].attributes.index)"
                                     disabled>
                            </v-input>
                            <v-input label="食事"
                                     id="display-diet"
                                     type="text"
                                     :unit="dailyReport.relationships.dailyReportDetails[3][0].attributes.point + '点'"
                                     :value="diet(dailyReport.relationships.dailyReportDetails[3][0].attributes.index)"
                                     disabled>
                            </v-input>
                            <v-input label="薬"
                                     id="display-medicine"
                                     type="text"
                                     :unit="dailyReport.relationships.dailyReportDetails[4][0].attributes.point + '点'"
                                     :value="medicine(dailyReport.relationships.dailyReportDetails[4][0].attributes.index)"
                                     disabled>
                            </v-input>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-xl-6 pb-3">
                    <h5 class="font-weight-bold pb-3">#主観的症状</h5>
                    <form>
                        <div class="form">
                            <v-input label="今の気分"
                                     id="display-feeling"
                                     type="text"
                                     :unit="dailyReport.relationships.dailyReportDetails[0][0].attributes.point + '点'"
                                     :value="feeling(dailyReport.relationships.dailyReportDetails[0][0].attributes.index)"
                                     disabled>
                            </v-input>
                            <v-input label="症状①"
                                     id="display-symptom1"
                                     type="text"
                                     :unit="dailyReport.relationships.dailyReportDetails[11][0].attributes.point + '点'"
                                     :value="symptomatology(dailyReport.relationships.dailyReportDetails[11][0].attributes.index)"
                                     disabled>
                            </v-input>
                            <v-input label="症状②"
                                     id="display-symptom2"
                                     type="text"
                                     :unit="dailyReport.relationships.dailyReportDetails[12][0].attributes.point + '点'"
                                     :value="symptomatology(dailyReport.relationships.dailyReportDetails[12][0].attributes.index)"
                                     disabled>
                            </v-input>
                            <v-input label="症状③"
                                     id="display-symptom3"
                                     type="text"
                                     :unit="dailyReport.relationships.dailyReportDetails[13][0].attributes.point + '点'"
                                     :value="symptomatology(dailyReport.relationships.dailyReportDetails[13][0].attributes.index)"
                                     disabled>
                            </v-input>
                            <v-input label="症状④"
                                     id="display-symptom4"
                                     type="text"
                                     :unit="dailyReport.relationships.dailyReportDetails[14][0].attributes.point + '点'"
                                     :value="symptomatology(dailyReport.relationships.dailyReportDetails[14][0].attributes.index)"
                                     disabled>
                            </v-input>
                            <v-input label="症状⑤"
                                     id="display-symptom5"
                                     type="text"
                                     :unit="dailyReport.relationships.dailyReportDetails[15][0].attributes.point + '点'"
                                     :value="symptomatology(dailyReport.relationships.dailyReportDetails[15][0].attributes.index)"
                                     disabled>
                            </v-input>
                        </div>
                    </form>
                </div>
                <div class="col-xl-6 pb-3">
                    <h5 class="font-weight-bold pb-3">#気候</h5>
                    <form>
                        <div class="form">
                            <v-input label="天気"
                                     id="display-weather"
                                     type="text"
                                     :value="dailyReport.attributes.weather"
                                     disabled>
                            </v-input>
                            <v-input label="気温"
                                     id="display-temperature"
                                     type="text"
                                     disabled
                                     unit="度"
                                     :value="dailyReport.attributes.temperature">
                            </v-input>
                            <v-input label="気圧"
                                     id="display-pressure"
                                     type="text"
                                     :value="dailyReport.attributes.pressure"
                                     disabled
                                     unit="hPa">
                            </v-input>
                            <v-input label="湿度"
                                     id="display-humidity"
                                     type="text"
                                     :value="dailyReport.attributes.humidity"
                                     disabled
                                     unit="%">
                            </v-input>
                            <v-input label="日の出"
                                     id="display-sunrize"
                                     type="text"
                                     :value="this.$moment(dailyReport.attributes.sunrise).format('MM月DD日 HH時mm分')"
                                     disabled>
                            </v-input>
                            <v-input label="日の入"
                                     id="display-sunset"
                                     type="text"
                                     :value="this.$moment(dailyReport.attributes.sunset).format('MM月DD日 HH時mm分')"
                                     disabled>
                            </v-input>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <template #footer>
            <button type="button" class="btn btn-dark" @click="isOpened = false">戻る</button>
        </template>
    </CModal>
</template>

<script>
import VInput from "@/components/Forms/VInput";

export default {
    name: "DisplayDailyModal",
    components: {VInput},
    data: function () {
        return {
            isOpened: false,
            dailyReport: null
        }
    },
    methods: {
        openModal: function (dailyReport) {
            this.dailyReport = dailyReport;
            this.isOpened = true;
        }
    },
    computed: {
        sleepQuality: function () {
            return function (sleepQuality) {
                switch (sleepQuality){
                    case 0:
                        return '未選択'
                    case 1:
                        return '良い'
                    case 2:
                        return '普通'
                    case 3:
                        return '悪い'
                    default:
                        return '-'
                }
            }
        },
        diet: function () {
            return function (diet) {
                switch (diet){
                    case 0:
                        return '未選択'
                    case 1:
                        return '食べた'
                    case 2:
                        return '不規則'
                    case 3:
                        return '食べていない'
                    default:
                        return '-'
                }
            }
        },
        medicine: function () {
            return function (medicine) {
                switch (medicine){
                    case 0:
                        return '未選択'
                    case 1:
                        return '飲めている'
                    case 2:
                        return '飲めていない'
                    case 3:
                        return '非該当'
                    default:
                        return '-'
                }
            }
        },
        feeling: function () {
            return function (feeling) {
                switch (feeling){
                    case 0:
                        return '未選択'
                    case 1:
                        return '良い'
                    case 2:
                        return '普通'
                    case 3:
                        return '悪い'
                    default:
                        return '-'
                }
            }
        },
        symptomatology: function () {
            return function (symptomatology) {
                switch (symptomatology){
                    case 0:
                        return '未選択'
                    case 1:
                        return '元気'
                    case 2:
                        return '安心している'
                    case 3:
                        return '積極的な気分'
                    case 4:
                        return '心にゆとりがある'
                    case 5:
                        return '生活リズムが保てる'
                    case 6:
                        return '緊張する'
                    case 7:
                        return '不安になる'
                    case 8:
                        return '落ち着かなくなる'
                    case 9:
                        return '怒りっぽい'
                    case 10:
                        return '心細い、孤独感'
                    case 11:
                        return '憂鬱になる'
                    case 12:
                        return '強いだるさ'
                    case 13:
                        return '強い疲労感'
                    case 14:
                        return '強いイライラ'
                    case 15:
                        return '物忘れが激しい'
                    case 16:
                        return '何も考えられない'
                    case 17:
                        return '楽観しすぎる'
                    default:
                        return '-'
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
