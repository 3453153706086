<template>
    <CModal
        v-if="dailyReport"
        :title="'日報編集 <' + this.$moment(dailyReport.attributes.register_date).format('YYYY年MM月DD日') + '>'"
        color="dark"
        size="xl"
        :show.sync="isOpened">
        <div class="container-fluid p-3">
            <div class="row pb-5">
                <div class="col-xl-6 pb-3">
                    <form>
                        <h5 class="font-weight-bold pb-3">#バイタル</h5>
                        <div class="form">
                            <v-input v-model="form.pressure_max"
                                     label="最高血圧"
                                     id="edit-max"
                                     type="text"
                                     placeholder="入力必須"
                                     :errors="hasErrors('pressure_max')">
                            </v-input>
                            <v-input v-model="form.pressure_min"
                                     label="最低血圧"
                                     id="edit-min"
                                     type="text"
                                     placeholder="入力必須"
                                     :errors="hasErrors('pressure_min')">
                            </v-input>
                            <v-input v-model="form.pulse"
                                     label="脈拍"
                                     id="edit-pulse"
                                     type="text"
                                     placeholder="入力必須"
                                     :errors="hasErrors('pulse')">
                            </v-input>
                            <v-input v-model="form.body_temperature"
                                     label="体温"
                                     id="edit-body-temperature"
                                     type="text"
                                     unit="度"
                                     placeholder="入力必須"
                                     :errors="hasErrors('body_temperature')">
                            </v-input>
                        </div>
                    </form>
                </div>
                <div class="col-xl-6 pb-3">
                    <h5 class="font-weight-bold pb-3">#生活リズム</h5>
                    <form>
                        <div class="form">
                            <v-input @input="createBedTime($event)"
                                     :value="this.$moment(form.bed_time).format('HH:mm')"
                                     label="就寝時間"
                                     id="create-bed-time"
                                     type="time"
                                     placeholder="入力必須"
                                     :errors="hasErrors('bed_time')">
                            </v-input>
                            <v-input @input="createWakeTime($event)"
                                     :value="this.$moment(form.wake_time).format('HH:mm')"
                                     label="起床時間"
                                     id="create-wake-time"
                                     type="time"
                                     placeholder="入力必須"
                                     :errors="hasErrors('wake-time')">
                            </v-input>
<!--                            <div class="row pb-2">-->
<!--                                <div class="col-3 py-2">-->
<!--                                    就寝時間-->
<!--                                </div>-->
<!--                                <div class="col-7">-->
<!--                                    <vue-ctk-date-time-picker-->
<!--                                        id="edit-bed-time"-->
<!--                                        v-model="form.bed_time"-->
<!--                                        label="入力必須"-->
<!--                                        :formatted="'YYYY-MM-DD HH:mm'"-->
<!--                                        :format="'YYYY-MM-DD HH:mm:ss'"-->
<!--                                    ></vue-ctk-date-time-picker>-->
<!--                                    <div v-for="(error,index) in errors['bed_time']"-->
<!--                                         class="invalid"-->
<!--                                         :key="index">-->
<!--                                        {{ error }}-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="col-2">-->

<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="row pb-2">-->
<!--                                <div class="col-3 py-2">-->
<!--                                    起床時間-->
<!--                                </div>-->
<!--                                <div class="col-7">-->
<!--                                    <vue-ctk-date-time-picker-->
<!--                                        id="create-wake-time"-->
<!--                                        v-model="form.wake_time"-->
<!--                                        label="入力必須"-->
<!--                                        :formatted="'YYYY-MM-DD HH:mm'"-->
<!--                                        :format="'YYYY-MM-DD HH:mm:ss'"-->
<!--                                    ></vue-ctk-date-time-picker>-->
<!--                                    <div v-for="(error,index) in errors['wake_time']"-->
<!--                                         class="invalid"-->
<!--                                         :key="index">-->
<!--                                        {{ error }}-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="col-2">-->

<!--                                </div>-->
<!--                            </div>-->
                            <v-select id="edit-sleep-quality"
                                      label="睡眠の質"
                                      @input="addPoint(0,$event)"
                                      @selectedIndex="addIndex(0,$event)"
                                      :unit="form.dailyReportDetails[0].point + '点'"
                                      :errors="hasErrors('dailyReportDetails.0.point')">
                                <option v-for="(sleepQualityOption,index) in sleepQualityOptions"
                                        :key="index"
                                        :selected="form.dailyReportDetails[0].index === index"
                                        :value="sleepQualityOption.point">
                                    {{ sleepQualityOption.option }}
                                </option>
                            </v-select>
                            <v-select id="edit-diet"
                                      label="食事"
                                      @input="addPoint(1,$event)"
                                      @selectedIndex="addIndex(1,$event)"
                                      :unit="form.dailyReportDetails[1].point + '点'"
                                      :errors="hasErrors('dailyReportDetails.1.point')">
                                <option v-for="(dietOption,index) in dietOptions"
                                        :key="index"
                                        :selected="form.dailyReportDetails[1].index === index"
                                        :value="dietOption.point">
                                    {{ dietOption.option }}
                                </option>
                            </v-select>
                            <v-select id="edit-medicine"
                                      label="薬"
                                      @input="addPoint(2,$event)"
                                      @selectedIndex="addIndex(2,$event)"
                                      :unit="form.dailyReportDetails[2].point + '点'"
                                      :errors="hasErrors('dailyReportDetails.2.point')">
                                <option v-for="(medicineOption,index) in medicineOptions"
                                        :key="index"
                                        :selected="form.dailyReportDetails[2].index === index"
                                        :value="medicineOption.point">
                                    {{ medicineOption.option }}
                                </option>
                            </v-select>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-xl-6 pb-3">
                    <h5 class="font-weight-bold pb-3">#主観的症状</h5>
                    <form>
                        <div class="form">
                            <v-select id="edit-feeling"
                                      label="今の気分"
                                      @input="addPoint(3,$event)"
                                      @selectedIndex="addIndex(3,$event)"
                                      :unit="form.dailyReportDetails[3].point + '点'"
                                      :errors="hasErrors('dailyReportDetails.3.point')">
                                <option v-for="(feelingOption,index) in feelingOptions"
                                        :key="index"
                                        :selected="form.dailyReportDetails[3].index === index"
                                        :value="feelingOption.point">
                                    {{ feelingOption.option }}
                                </option>
                            </v-select>
                            <v-select id="edit-symptom1"
                                      label="症状①"
                                      @input="addPoint(4,$event)"
                                      @selectedIndex="addIndex(4,$event)"
                                      :unit="form.dailyReportDetails[4].point + '点'"
                                      :errors="hasErrors('dailyReportDetails.4.point')">
                                <option v-for="(symptomatologyOption,index) in symptomatologyOptions"
                                        :key="index"
                                        :selected="form.dailyReportDetails[4].index === index"
                                        :value="symptomatologyOption.point">
                                    {{ symptomatologyOption.option }}
                                </option>
                            </v-select>
                            <v-select id="edit-symptom2"
                                      label="症状②"
                                      @input="addPoint(5,$event)"
                                      @selectedIndex="addIndex(5,$event)"
                                      :unit="form.dailyReportDetails[5].point + '点'"
                                      :errors="hasErrors('dailyReportDetails.5.point')">
                                <option v-for="(symptomatologyOption,index) in symptomatologyOptions"
                                        :key="index"
                                        :selected="form.dailyReportDetails[5].index === index"
                                        :value="symptomatologyOption.point">
                                    {{ symptomatologyOption.option }}
                                </option>
                            </v-select>
                            <v-select id="edit-symptom3"
                                      label="症状③"
                                      @input="addPoint(6,$event)"
                                      @selectedIndex="addIndex(6,$event)"
                                      :unit="form.dailyReportDetails[6].point + '点'"
                                      :errors="hasErrors('dailyReportDetails.6.point')">
                                <option v-for="(symptomatologyOption,index) in symptomatologyOptions"
                                        :key="index"
                                        :selected="form.dailyReportDetails[6].index === index"
                                        :value="symptomatologyOption.point">
                                    {{ symptomatologyOption.option }}
                                </option>
                            </v-select>
                            <v-select id="edit-symptom4"
                                      label="症状④"
                                      @input="addPoint(7,$event)"
                                      @selectedIndex="addIndex(7,$event)"
                                      :unit="form.dailyReportDetails[7].point + '点'"
                                      :errors="hasErrors('dailyReportDetails.7.point')">
                                <option v-for="(symptomatologyOption,index) in symptomatologyOptions"
                                        :key="index"
                                        :selected="form.dailyReportDetails[7].index === index"
                                        :value="symptomatologyOption.point">
                                    {{ symptomatologyOption.option }}
                                </option>
                            </v-select>
                            <v-select id="edit-symptom5"
                                      label="症状⑤"
                                      @input="addPoint(8,$event)"
                                      @selectedIndex="addIndex(8,$event)"
                                      :unit="form.dailyReportDetails[8].point + '点'"
                                      :errors="hasErrors('dailyReportDetails.8.point')">
                                <option v-for="(symptomatologyOption,index) in symptomatologyOptions"
                                        :key="index"
                                        :selected="form.dailyReportDetails[8].index === index"
                                        :value="symptomatologyOption.point">
                                    {{ symptomatologyOption.option }}
                                </option>
                            </v-select>
                        </div>
                    </form>
                </div>
                <div class="col-xl-6 pb-3">
                    <h5 class="font-weight-bold pb-3">#気候</h5>
                    <form>
                        <div class="form">
                            <v-input v-model="form.weather"
                                     label="天気"
                                     id="edit-weather"
                                     type="text"
                                     placeholder="入力必須"
                                     :errors="hasErrors('weather')">
                            </v-input>
                            <v-input v-model="form.temperature"
                                     label="気温"
                                     id="edit-temperature"
                                     type="text"
                                     unit="度"
                                     placeholder="入力必須"
                                     :errors="hasErrors('temperature')">
                            </v-input>
                            <v-input v-model="form.pressure"
                                     label="気圧"
                                     id="edit-pressure"
                                     type="text"
                                     unit="hPa"
                                     placeholder="入力必須"
                                     :errors="hasErrors('pressure')">
                            </v-input>
                            <v-input v-model="form.humidity"
                                     label="湿度"
                                     id="edit-humidity"
                                     type="text"
                                     unit="%"
                                     placeholder="入力必須"
                                     :errors="hasErrors('humidity')">
                            </v-input>
                            <div class="row pb-2">
                                <div class="col-3 py-2">
                                    日の出
                                </div>
                                <div class="col-7">
                                    <vue-ctk-date-time-picker
                                        id="edit-sunrize"
                                        v-model="form.sunrise"
                                        label="入力必須"
                                        :formatted="'YYYY-MM-DD HH:mm'"
                                        :format="'YYYY-MM-DD HH:mm:ss'"
                                    ></vue-ctk-date-time-picker>
                                    <div v-for="(error,index) in errors['sunrise']"
                                         class="invalid"
                                         :key="index">
                                        {{ error }}
                                    </div>
                                </div>
                                <div class="col-2">

                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="col-3 py-2">
                                    日の入
                                </div>
                                <div class="col-7">
                                    <vue-ctk-date-time-picker
                                        id="edit-sunset"
                                        v-model="form.sunset"
                                        label="入力必須"
                                        :formatted="'YYYY-MM-DD HH:mm'"
                                        :format="'YYYY-MM-DD HH:mm:ss'"
                                    ></vue-ctk-date-time-picker>
                                    <div v-for="(error,index) in errors['sunset']"
                                         class="invalid"
                                         :key="index">
                                        {{ error }}
                                    </div>
                                </div>
                                <div class="col-2">

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <template #footer>
            <button type="button" class="btn btn-dark" @click="updateDailyReport" :disabled="sending">更新</button>
        </template>
    </CModal>
</template>

<script>
import VInput from "@/components/Forms/VInput";
import VSelect from "@/components/Forms/VSelect";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
import VDateTime from "@/components/Forms/VDateTime";

export default {
    name: "EditDailyReportModal",
    components: {VDateTime, VSelect, VInput},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            value: '',
            isOpened: false,
            sending: false,
            dailyReport: null,
            sleepQualityOptions: [
                {
                    option: '未選択',
                    point: 0
                },
                {
                    option: '良い',
                    point: 10
                },
                {
                    option: '普通',
                    point: 5
                },
                {
                    option: '悪い',
                    point: 1
                },
            ],
            dietOptions: [
                {
                    option: '未選択',
                    point: 0
                },
                {
                    option: '食べた',
                    point: 10
                },
                {
                    option: '不規則',
                    point: 5
                },
                {
                    option: '食べていない',
                    point: 1
                },
            ],
            medicineOptions: [
                {
                    option: '未選択',
                    point: 0
                },
                {
                    option: '飲めている',
                    point: 10
                },
                {
                    option: '飲めていない',
                    point: 1
                },
                {
                    option: '非該当',
                    point: 0
                },
            ],
            feelingOptions: [
                {
                    option: '未選択',
                    point: 0
                },
                {
                    option: '良い',
                    point: 10
                },
                {
                    option: '普通',
                    point: 5
                },
                {
                    option: '悪い',
                    point: 1
                },
            ],
            symptomatologyOptions: [
                {
                    option: '未選択',
                    point: 0
                },
                {
                    option: '元気',
                    point: 10
                },
                {
                    option: '安心している',
                    point: 10
                },
                {
                    option: '積極的な気分',
                    point: 10
                },
                {
                    option: '心にゆとりがある',
                    point: 10
                },
                {
                    option: '生活リズムが保てる',
                    point: 10
                },
                {
                    option: '緊張する',
                    point: 0
                },
                {
                    option: '不安になる',
                    point: 0
                },
                {
                    option: '落ち着かなくなる',
                    point: 0
                },
                {
                    option: '怒りっぽい',
                    point: 0
                },
                {
                    option: '心細い、孤独感',
                    point: 0
                },
                {
                    option: '憂鬱になる',
                    point: 0
                },
                {
                    option: '強いだるさ',
                    point: -10
                },
                {
                    option: '強い疲労感',
                    point: -10
                },
                {
                    option: '強いイライラ',
                    point: -10
                },
                {
                    option: '物忘れが激しい',
                    point: -10
                },
                {
                    option: '何も考えられない',
                    point: -10
                },
                {
                    option: '楽観しすぎる',
                    point: -10
                },
            ],
            form: {
                id: '',
                pressure_max: '',
                pressure_min: '',
                pulse: '',
                body_temperature: '',
                bed_time: '',
                wake_time: '',
                weather: '',
                temperature: '',
                pressure: '',
                humidity: '',
                sunrise: '',
                sunset: '',
                daytime: '',
                dailyReportDetails: [
                    {
                        id: '',
                        position: 2,
                        point: '',
                        index: ''
                    },
                    {
                        id: '',
                        position: 3,
                        point: '',
                        index: ''
                    },
                    {
                        id: '',
                        position: 4,
                        point: '',
                        index: ''
                    },
                    {
                        id: '',
                        position: 0,
                        point: '',
                        index: ''
                    },
                    {
                        id: '',
                        position: 11,
                        point: '',
                        index: ''
                    },
                    {
                        id: '',
                        position: 12,
                        point: '',
                        index: ''
                    },
                    {
                        id: '',
                        position: 13,
                        point: '',
                        index: ''
                    },
                    {
                        id: '',
                        position: 14,
                        point: '',
                        index: ''
                    },
                    {
                        id: '',
                        position: 15,
                        point: '',
                        index: ''
                    }
                ]
            }
        }
    },
    methods: {
        updateDailyReport: function () {
            this.sending = true;
            window.axios.patch(this.routes.dailyReport(this.form.id), this.form)
                .then((response) => {
                    this.showSuccessPopup("更新しました。");
                    this.$emit("daily-report-has-updated", response.data.data);
                    this.closeModal();
                })
                .catch((error) => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        openModal: function (dailyReport) {
            this.clearErrors();
            this.addToForm(dailyReport);
            this.isOpened = true;
        },
        closeModal: function () {
            this.isOpened = false;
        },
        addToForm: function (dailyReport) {
            this.dailyReport = dailyReport;
            this.form.id = dailyReport.id;
            this.form.person_id = dailyReport.attributes.person_id;
            this.form.register_date = dailyReport.attributes.register_date;
            this.form.pressure_max = dailyReport.attributes.pressure_max;
            this.form.pressure_min = dailyReport.attributes.pressure_min;
            this.form.pulse = dailyReport.attributes.pulse;
            this.form.body_temperature = dailyReport.attributes.body_temperature;
            this.form.bed_time = dailyReport.attributes.bed_time;
            this.form.wake_time = dailyReport.attributes.wake_time;
            this.form.weather = dailyReport.attributes.weather;
            this.form.temperature = dailyReport.attributes.temperature;
            this.form.pressure = dailyReport.attributes.pressure;
            this.form.humidity = dailyReport.attributes.humidity;
            this.form.sunrise = dailyReport.attributes.sunrise;
            this.form.sunset = dailyReport.attributes.sunset;
            this.form.daytime = dailyReport.attributes.daytime;
            this.addToDailyReportDetails(dailyReport, 0, 2);
            this.addToDailyReportDetails(dailyReport, 1, 3);
            this.addToDailyReportDetails(dailyReport, 2, 4);
            this.addToDailyReportDetails(dailyReport, 3, 0);
            this.addToDailyReportDetails(dailyReport, 4, 11);
            this.addToDailyReportDetails(dailyReport, 5, 12);
            this.addToDailyReportDetails(dailyReport, 6, 13);
            this.addToDailyReportDetails(dailyReport, 7, 14);
            this.addToDailyReportDetails(dailyReport, 8, 15);
        },
        addToDailyReportDetails: function (dailyReport, formIndex, dataIndex) {
            this.form.dailyReportDetails[formIndex].id = dailyReport.relationships.dailyReportDetails[dataIndex][0].id;
            this.form.dailyReportDetails[formIndex].point = dailyReport.relationships.dailyReportDetails[dataIndex][0].attributes.point;
            this.form.dailyReportDetails[formIndex].index = dailyReport.relationships.dailyReportDetails[dataIndex][0].attributes.index;
            this.form.dailyReportDetails[formIndex].position = dailyReport.relationships.dailyReportDetails[dataIndex][0].attributes.position;
        },
        addIndex: function (arrayIndex, optionIndex) {
            this.$set(this.form.dailyReportDetails[arrayIndex], 'index', optionIndex);
        },
        addPoint: function (arrayIndex, point) {
            this.$set(this.form.dailyReportDetails[arrayIndex], 'point', point);
        },
        createBedTime: function ($event) {
            this.form.bed_time = '';

            if (!this.$moment(this.form.register_date, 'YYYY-MM-DD').isValid()) {
                return;
            }

            if ($event <= '23:59' && $event >= '18:00') {
                const date = this.$moment(this.form.register_date).subtract(1,'days').format('YYYY-MM-DD');
                this.form.bed_time = this.$moment(date + ' ' + $event).format('YYYY-MM-DD HH:mm:ss');
            }else {
                const date = this.$moment(this.form.register_date).format('YYYY-MM-DD');
                this.form.bed_time = this.$moment(date + ' ' + $event).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        createWakeTime: function ($event) {
            this.form.wake_time = '';

            if (!this.$moment(this.form.register_date, 'YYYY-MM-DD').isValid()) {
                return;
            }

            const date = this.$moment(this.form.register_date).format('YYYY-MM-DD');
            this.form.wake_time = this.$moment(date + ' ' + $event).format('YYYY-MM-DD HH:mm:ss');
        },
    }
}
</script>

<style src="./EditDailyReportModal.scss" lang="scss" scoped/>
