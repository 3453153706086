<template>
  <div>
    <div v-if="dailyReports.length > 0">
      <table class="table table-bordered bg-white">
        <thead class="bg-dark">
        <th style="width: 15%">日付</th>
        <th style="width: 15%">作成者</th>
        <th style="width: 15%">作成日</th>
        <th style="width: 15%">最終更新者</th>
        <th style="width: 15%">最終更新日</th>
        <th style="width: 8%">表示</th>
        <th style="width: 8%">編集</th>
        <th style="width: 8%">削除</th>
        </thead>
        <tbody>
        <tr v-for="dailyReport in dailyReports">
          <td>{{ dailyReport.attributes.register_date | moment("YYYY年MM月DD日") }}</td>
          <td>{{ dailyReport.relationships.user.attributes.name }}</td>
          <td>{{ dailyReport.attributes.created_at | moment("YYYY年MM月DD日") }}</td>
          <td>{{ dailyReport.relationships.updateUser.attributes.name }}</td>
          <td>{{ dailyReport.attributes.updated_at | moment("YYYY年MM月DD日") }}</td>
          <td><a href="javascript:void(0)" @click="openDisplayModal(dailyReport)">表示</a></td>
          <td><a href="javascript:void(0)" @click="openEditModal(dailyReport)">編集</a></td>
          <td><a href="javascript:void(0)" @click="openDeleteModal(dailyReport)">削除</a></td>
        </tr>
        </tbody>
      </table>
      <edit-daily-report-modal @daily-report-has-updated="$emit('daily-report-has-updated',$event)"
                               ref="editModal"></edit-daily-report-modal>
      <display-daily-modal ref="displayModal"></display-daily-modal>
      <delete-daily-report-modal @dailyReport-has-deleted="$emit('dailyReport-has-deleted',$event)"
          ref="deleteModal"></delete-daily-report-modal>
    </div>
    <div v-else>
      <table class="table table-bordered bg-white">
        <thead class="bg-dark">
        <th style="width: 15%">日付</th>
        <th style="width: 15%">作成者</th>
        <th style="width: 15%">作成日</th>
        <th style="width: 15%">最終更新者</th>
        <th style="width: 15%">最終更新日</th>
        <th style="width: 8%">表示</th>
        <th style="width: 8%">編集</th>
        <th style="width: 8%">削除</th>
        </thead>
      </table>
      <h5>
        日報はありません
      </h5>
    </div>
  </div>
</template>

<script>
import EditDailyReportModal from "@/components/DailyReports/EditDailyReportModal/EditDailyReportModal";
import DisplayDailyModal from "@/components/DailyReports/DisplayDailyModal/DisplayDailyModal";
import DeleteDailyReportModal from "@/components/DailyReports/DeleteDailyReportModal/DeleteDailyReportModal";

export default {
  name: "DailyReportList",
  components: {DeleteDailyReportModal, DisplayDailyModal, EditDailyReportModal},
  props: {
    dailyReports: {
      type: Array,
      require: false,
      default: null
    },
  },
  methods: {
    openEditModal: function (dailyReport) {
      this.$refs['editModal'].openModal(dailyReport);
    },
    openDisplayModal: function (dailyReport) {
      this.$refs['displayModal'].openModal(dailyReport);
    },
    openDeleteModal: function (dailyReport) {
      this.$refs['deleteModal'].openModal(dailyReport);
    }
  }
}
</script>

<style scoped>

</style>
