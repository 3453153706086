<template>
    <div v-if="dailyReports.length > 0">
        <h3 class="my-4">#生活リズム</h3>
        <table class="table table-bordered bg-white">
            <thead class="bg-dark">
            <tr>
                <th style="width: 25%"></th>
                <th style="width: 25%">平均</th>
                <th style="width: 25%">ばらつき</th>
                <th style="width: 25%">ランク</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row" class="bg-dark">就寝時間</th>
                <td>{{ aveTime(calcTotalMinutes('bed_time')) }}</td>
                <td>{{ displayTime(standardDeviationSleep('bed_time')) }}</td>
                <td>{{ sleepTimeRank(standardDeviationSleep('bed_time')) }}</td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">起床時間</th>
                <td>{{ aveTime(calcTotalMinutes('wake_time')) }}</td>
                <td>{{ displayTime(standardDeviationSleep('wake_time')) }}</td>
                <td>{{ sleepTimeRank(standardDeviationSleep('wake_time')) }}</td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">睡眠量</th>
                <td>{{ aveTotalSleepTime() }}</td>
                <td>{{ standardDeviationSleepAmount() }}</td>
                <td>ー</td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">睡眠の質</th>
                <td>{{ ave(2) }}</td>
                <td>{{ standardDeviation(2) }}</td>
                <td>ー</td>
            <tr>
                <th scope="row" class="bg-dark">食事</th>
                <td>{{ ave(3) }}</td>
                <td>{{ standardDeviation(3) }}</td>
                <td>{{ rank(standardDeviation(3)) }}</td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">薬</th>
                <td>{{ ave(4) }}</td>
                <td>{{ standardDeviation(4) }}</td>
                <td>ー</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div v-else>
        <h3 class="my-4">#生活リズム</h3>
        <table class="table table-bordered bg-white">
            <thead class="bg-dark">
            <tr>
                <th style="width: 25%"></th>
                <th style="width: 25%">平均</th>
                <th style="width: 25%">ばらつき</th>
                <th style="width: 25%">ランク</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row" class="bg-dark">就寝時間</th>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">起床時間</th>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">睡眠量</th>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">睡眠の質</th>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">食事</th>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">薬</th>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "DailyReportRhythm",
    props: {
        dailyReports: {
            type: Array,
            require: false,
            default: null
        },
    },
    data: function () {
        return {
            hours: {
                '18': 18, '19': 19, '20': 20, '21': 21,
                '22': 22, '23': 23, '00': 24, '01': 25,
                '02': 26, '03': 27, '04': 28, '05': 29,
                '06': 30, '07': 31, '08': 32, '09': 33,
                '10': 34, '11': 35, '12': 36, '13': 37,

                '14': 38, '15': 39, '16': 40, '17': 41,
            }
        }
    },
    methods: {
        standardDeviationSleepAmount: function () {
            if (this.isDailyReports()) {
                const totalMinutesAve = this.totalMinutesAve();

                const sd = Math.sqrt(
                    this.dailyReports.map((current) => {
                            const dateTo = this.$moment(current.attributes.bed_time);
                            const dateFrom = this.$moment(current.attributes.wake_time);
                            const totalMinutes = this.floor(dateFrom.diff(dateTo) / (60 * 1000), 10);
                            const difference = totalMinutes - totalMinutesAve;
                            return difference ** 2;
                        }
                    )
                        .reduce((prev, current) =>
                            prev + current
                        ) / this.dailyReports.length
                );

                let hour = Math.floor(sd / 60);
                let minute = sd % 60;

                return this.$moment({hour: hour, minute: minute}).format('HH:mm');
            }
        },
        totalMinutesAve: function () {
            if (this.dailyReports.length > 0) {
                let sleepTime = [];

                Object.keys(this.dailyReports).forEach(function (key) {
                    const dateTo = this.$moment(this.dailyReports[key].attributes.bed_time);
                    const dateFrom = this.$moment(this.dailyReports[key].attributes.wake_time);
                    sleepTime.push(this.floor(dateFrom.diff(dateTo) / (60 * 1000), 10));
                }, this)

                return this.floor(sleepTime.reduce((prev, current) => prev + current, 0) / this.dailyReports.length, 1);
            }
        },
        aveTotalSleepTime: function () {
            if (this.dailyReports.length > 0) {
                const average = this.totalMinutesAve();

                let hour = Math.floor(average / 60);
                let minute = average % 60;

                if (hour >= 24) {
                    return hour + ':' + this.$moment({minute: minute}).format('mm');
                }

                return this.$moment({hour: hour, minute: minute}).format('HH:mm');
            } else {
                return ''
            }
        },
        isDailyReports: function () {
            return this.dailyReports.length > 0;
        },
        floor: function (data, digit) {
            return Math.floor(data * digit) / digit;
        },
        round: function (data, digit) {
            if (data === 0) return 0;
            return Math.round(data * digit) / digit;
        },
        ave: function (index) {
            if (this.isDailyReports()) {
                const sum = this.dailyReports.reduce((prev, current) =>
                    prev + current['relationships']['dailyReportDetails'][index][0]['attributes']['point'], 0);
                const ave = sum / this.dailyReports.length;
                return this.round(ave, 1);
            }
        },
        aveTime: function (totalMinutes) {
            if (typeof (totalMinutes) !== 'number') {
                return;
            }

            if (this.isDailyReports()) {
                let hour = Math.floor(totalMinutes / 60);
                let minute = totalMinutes % 60;

                if (hour >= 24) {
                    hour = hour - 24;
                }
                return this.$moment({hour: hour, minute: minute}).format('HH:mm');
            }
        },
        calcTotalMinutes: function (prop) {
            if (this.isDailyReports()) {
                const hours = this.dailyReports.reduce((prev, current) =>
                    prev + this.hours[this.$moment(current['attributes'][prop]).format('HH')], 0);
                const minutes = this.dailyReports.reduce((prev, current) =>
                    prev + parseInt(this.$moment(current['attributes'][prop]).format('mm')), 0);

                const totalMinutes = hours * 60 + minutes;

                return this.round(totalMinutes / this.dailyReports.length, 1);
            }
        },
        standardDeviation: function (index) {
            if (this.isDailyReports()) {
                const average =
                    this.dailyReports.reduce((prev, current) => prev + current['relationships']['dailyReportDetails'][index][0]['attributes']['point'], 0)
                    / this.dailyReports.length;

                const sd = Math.sqrt(
                    this.dailyReports.map((current) => {
                        let difference = current['relationships']['dailyReportDetails'][index][0]['attributes']['point'] - average;
                        return difference ** 2;
                    })
                        .reduce((prev, current) =>
                            prev + current
                        ) / this.dailyReports.length
                );

                return this.round(sd, 1);
            }
        },
        standardDeviationSleep: function (prop) {
            if (this.isDailyReports()) {
                const hours = this.dailyReports.reduce((prev, current) =>
                    prev + this.hours[this.$moment(current['attributes'][prop]).format('HH')], 0);
                const minutes = this.dailyReports.reduce((prev, current) =>
                    prev + parseInt(this.$moment(current['attributes'][prop]).format('mm')), 0);

                const totalMinutes = hours * 60 + minutes;

                const average = this.round(totalMinutes / this.dailyReports.length, 1);

                const sd = Math.sqrt(
                    this.dailyReports.map((current) => {
                        let hours = this.hours[this.$moment(current['attributes'][prop]).format('HH')];
                        let minutes = parseInt(this.$moment(current['attributes'][prop]).format('mm'));
                        let totalMinutes = hours * 60 + minutes;
                        let difference = totalMinutes - average;
                        return difference ** 2;
                    })
                        .reduce((prev, current) =>
                            prev + current
                        ) / this.dailyReports.length
                );

                return this.round(sd, 1);
            }
        },
        displayTime: function (totalMinutes) {
            if (typeof (totalMinutes) === 'number') {
                let hour = Math.floor(totalMinutes / 60);
                let minute = totalMinutes % 60;

                return this.$moment({hour: hour, minute: minute}).format('HH:mm');
            }
        },
        rank: function (data) {
            if (typeof (data) !== 'number') {
                return '';
            }

            return data === 1 ? 'A' : 'B';

        },
        sleepTimeRank: function (data) {
            if (typeof (data) !== 'number') {
                return '';
            }

            return data < 60 ? 'A' : 'B';
        },
    }
}
</script>

<style scoped>

</style>
