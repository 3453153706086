<template>
    <div v-if="dailyReports.length > 0">
        <h3 class="my-4">#バイタル</h3>
        <table class="table table-bordered bg-white">
            <thead class="bg-dark">
            <tr>
                <th style="width: 24%"></th>
                <th style="width: 19%">最高</th>
                <th style="width: 19%">最低</th>
                <th style="width: 19%">平均</th>
                <th style="width: 19%">ばらつき</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row" class="bg-dark">血圧(最高)</th>
                <td>{{ max('pressure_max') }}</td>
                <td>{{ min('pressure_max') }}</td>
                <td>{{ ave('pressure_max') }}</td>
                <td>{{ standardDeviation('pressure_max') }}
                </td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">血圧(最低)</th>
                <td>{{ max('pressure_min') }}</td>
                <td>{{ min('pressure_min') }}</td>
                <td>{{ ave('pressure_min') }}</td>
                <td>{{ standardDeviation('pressure_min') }}
                </td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">血圧(平均)</th>
                <td>{{ max('pressure_ave') }}</td>
                <td>{{ min('pressure_ave') }}</td>
                <td>{{ ave('pressure_ave') }}</td>
                <td>{{ standardDeviation('pressure_ave') }}
                </td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">脈拍</th>
                <td>{{ max('pulse') }}</td>
                <td>{{ min('pulse') }}</td>
                <td>{{ ave('pulse') }}</td>
                <td>{{ standardDeviation('pulse') }}
                </td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">体温</th>
                <td>{{ maxFloat('body_temperature') }}</td>
                <td>{{ minFloat('body_temperature') }}</td>
                <td>{{ aveFloat('body_temperature') }}</td>
                <td>{{ standardDeviationFloat('body_temperature') }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div v-else>
        <h3 class="my-4">#バイタル</h3>
        <table class="table table-bordered bg-white">
            <thead class="bg-dark">
            <tr>
                <th style="width: 24%"></th>
                <th style="width: 19%">最高</th>
                <th style="width: 19%">最低</th>
                <th style="width: 19%">平均</th>
                <th style="width: 19%">ばらつき</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row" class="bg-dark">血圧(最高)</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">血圧(最低)</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">血圧(平均)</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">脈拍</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th scope="row" class="bg-dark">体温</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "DailyReportVital",
    props: {
        dailyReports: {
            type: Array,
            require: false,
            default: null
        },
    },
    methods: {
        isDailyReports: function () {
            return this.dailyReports.length > 0;
        },
        round: function (data, digit) {//四捨五入
            if (data === 0) return 0;
            return Math.round(data * digit) / digit;
        },
        max: function (prop) {
            if (this.isDailyReports()) {
                return Math.max.apply(null, this.dailyReports.map(function (dailyReport) {
                    return parseInt(dailyReport['attributes'][prop]);
                }))
            }
        },
        min: function (prop) {
            if (this.isDailyReports()) {
                return Math.min.apply(null, this.dailyReports.map(function (dailyReport) {
                    return parseInt(dailyReport['attributes'][prop]);
                }))
            }
        },
        ave: function (prop) {
            if (this.isDailyReports()) {
                const sum = this.dailyReports.reduce((prev, current) => prev + parseInt(current['attributes'][prop]), 0);
                const ave = sum / this.dailyReports.length;
                return this.round(ave,1);
            }
        },
        standardDeviation: function (prop) {
            if (this.isDailyReports()) {
                const average =
                    this.dailyReports.reduce((prev, current) => prev + parseInt(current['attributes'][prop]), 0)
                    / this.dailyReports.length;

                const sd = Math.sqrt(this.dailyReports.map((current) => {
                    let difference = parseInt(current['attributes'][prop]) - average;
                    return difference ** 2;
                })
                    .reduce((prev, current) => prev + current) / this.dailyReports.length);

                return this.round(sd,1);
            }
        },
        maxFloat: function (prop) {
            if (this.isDailyReports()) {
                return Math.max.apply(null, this.dailyReports.map(function (dailyReport) {
                    return parseFloat(dailyReport['attributes'][prop]);
                }))
            }
        },
        minFloat: function (prop) {
            if (this.isDailyReports()) {
                return Math.min.apply(null, this.dailyReports.map(function (dailyReport) {
                    return parseFloat(dailyReport['attributes'][prop]);
                }))
            }
        },
        aveFloat: function (prop) {
            if (this.isDailyReports()) {
                const sum = this.dailyReports.reduce((prev, current) => prev + parseFloat(current['attributes'][prop]), 0);
                const ave = sum / this.dailyReports.length;
                return this.round(ave,10);
            }
        },
        standardDeviationFloat: function (prop) {
            if (this.isDailyReports()) {
                const average =
                    this.dailyReports.reduce((prev, current) => prev + parseFloat(current['attributes'][prop]), 0)
                    / this.dailyReports.length;

                const sd = Math.sqrt(this.dailyReports.map((current) => {
                    let difference = parseFloat(current['attributes'][prop]) - average;
                    return difference ** 2;
                })
                    .reduce((prev, current) => prev + current) / this.dailyReports.length);

                return this.round(sd,10);
            }
        },
    }
}
</script>

<style scoped>

</style>
